import { ref, computed } from "vue";
import { useRoute } from 'vue-router'
import { TimeLine } from "@/types/time_line"
import { TimeLineComment } from "@/types/time_line_comment";
import { TimeLineRepository } from '@/repositories/TimeLineRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
export const TimeLineLogic = () => {
    const route = useRoute();
    const projectId = computed(() => Number(route.params.project_id))

    const timeLineLoading = ref(false)
    const time_line = ref<TimeLine>()
    const get = async (time_line_id: number) => {
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            time_line.value = await repository.get(time_line_id);
            timeLineLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    };
    const updateTimeLine = async (time_line_id: number, body: string): Promise<boolean> => {
        // 表示されたものと違う場合は何もしない
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            time_line.value = await repository.update(time_line_id, body);
            timeLineLoading.value = false;
            return true;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false;
        }

    };
    const addTimeLine = async (body: string): Promise<boolean> => {
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            await repository.add(body);
            timeLineLoading.value = false
            return true

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false
        }

    };
    const deleteTimeLine = async (time_line_id: number): Promise<boolean> => {
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            await repository.delete(time_line_id)
            timeLineLoading.value = false;
            return true

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false
        }

    };
    const iine = async (time_line_id: number) => {
        timeLineLoading.value = true

        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const iine_info = await repository.iine(time_line_id);
            if (time_line.value?.id == time_line_id) {
                time_line.value.iine_count = iine_info.iine_count
                time_line.value.iine_users = iine_info.iine_users
                time_line.value.iine = true
            }
            timeLineLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    };
    const cancelIine = async (time_line_id: number) => {
        timeLineLoading.value = true
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const iine_info = await repository.cancelIine(time_line_id);
            if (time_line.value?.id == time_line_id) {
                time_line.value.iine_count = iine_info.iine_count
                time_line.value.iine_users = iine_info.iine_users
                time_line.value.iine = false
            }
            timeLineLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    }
    const comment = async (time_line_id: number, body: string) => {
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const time_line_comment: TimeLineComment = await repository.sendComment(time_line_id, body);
            if (time_line.value?.id == time_line_id) {
                time_line.value.time_line_comments.unshift(time_line_comment)
            }
            timeLineLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");

        }

    }
    const deleteComment = async (time_line_id: number, comment_id: number) => {
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            await repository.deleteComment(time_line_id, comment_id);
            if (time_line.value?.id == time_line_id) {
                for (let i = 0; i < time_line.value.time_line_comments.length; i++) {
                    if (time_line.value.time_line_comments[i].id == comment_id) {
                        time_line.value.time_line_comments.splice(i, 1)
                    }
                }
            }
            timeLineLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");

        }
    }
    const updateComment = async (time_line_id: number, comment_id: number, body: string): Promise<boolean> => {
        // 表示されたものと違う場合は何もしない
        timeLineLoading.value = true;
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const time_line_comment = await repository.updateComment(time_line_id, comment_id, body);
            if (time_line.value) {
                for (let i = 0; i < time_line.value.time_line_comments.length; i++) {
                    if (time_line.value.time_line_comments[i].id == comment_id) {
                        time_line.value.time_line_comments[i] = time_line_comment
                    }
                }
            }
            timeLineLoading.value = false;
            return true;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false;
        }

    }
    const commentIine = async (time_line_id: number, comment_id: number) => {
        timeLineLoading.value = true

        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const iine_count = await repository.commentIine(time_line_id, comment_id);
            if (time_line.value?.id == time_line_id) {
                for (let i = 0; i < time_line.value.time_line_comments.length; i++) {
                    if (time_line.value.time_line_comments[i].id == comment_id) {
                        time_line.value.time_line_comments[i].iine_count = iine_count
                        time_line.value.time_line_comments[i].iine = true
                    }
                }
            }
            timeLineLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    };
    const commentCancelIine = async (time_line_id: number, comment_id: number) => {
        timeLineLoading.value = true
        const repository: TimeLineRepository = new TimeLineRepository(projectId.value);
        try {
            const iine_count = await repository.commentCancelIine(time_line_id, comment_id);
            if (time_line.value?.id == time_line_id) {
                for (let i = 0; i < time_line.value.time_line_comments.length; i++) {
                    if (time_line.value.time_line_comments[i].id == comment_id) {
                        time_line.value.time_line_comments[i].iine_count = iine_count
                        time_line.value.time_line_comments[i].iine = false
                    }
                }
            }
            timeLineLoading.value = false;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }
    }



    const timeLineStateReturn = () => {
        return {
            get,
            addTimeLine,
            updateTimeLine,
            deleteTimeLine,
            time_line,
            iine,
            cancelIine,
            comment,
            updateComment,
            deleteComment,
            commentIine,
            commentCancelIine,
            timeLineLoading
        }
    }
    return {
        timeLineStateReturn,
        ...timeLineStateReturn()
    }
}



